import React from 'react'
import '../../styles/global.scss'
import AlertBar from '../AlertBar'
import AlertConfig from '../../ALERTBAR_CONFIG'
import Footer from '../Footer'
import Navbar from '../navigation/navbar'

function IndexDark(props) {
	return (
		<>
			{AlertConfig.enabled && <AlertBar />}
			<Navbar
				dark={props.dark || undefined}
				navy={props.navy || undefined}
			/>
			<div style={{ marginTop: AlertConfig.enabled ? '100px' : '60px' }}>
				{props.children}
			</div>
			<Footer />
		</>
	)
}

export default IndexDark

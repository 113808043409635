import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import ButtonLink from '../../components/ButtonLink'
import UpdatesCarousel from '../../components/UpdatesCarousel'
import v11 from '../../images/v1_1.webp'
import v12 from '../../images/v1_2.webp'
import v13 from '../../images/v1_3.webp'
import IndexDark from '../../components/layouts/dark'
import Container from '../../components/Container'

function updates({ data }) {
	const updates = data.allWpPost.nodes

	const launch = [
		{
			body: 'Some designs from the very first iteration of Classify.',
			image: v11,
		},
		{
			body: 'Some designs from the very first iteration of Classify.',
			image: v12,
		},
		{
			body: 'Some designs from the very first iteration of Classify.',
			image: v13,
		},
	]

	return (
		<>
			<Helmet>
				<title>Classify — Updates</title>
				<meta
					name="description"
					content="Classify is constantly improving with new features to enhance your experience."
				/>
				<link
					rel="canonical"
					href="https://classify.org.uk/blog/updates"
				/>

				<meta property="og:title" content="Classify — Updates" />
				<meta
					property="og:url"
					content="https://classify.org.uk/blog/updates"
				/>
				<meta
					property="og:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="og:description"
					content="Classify is constantly improving with new features to enhance your experience."
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta property="twitter:title" content="Classify — Updates" />
				<meta
					name="twitter:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="twitter:description"
					content="Classify is constantly improving with new features to enhance your experience."
				/>
			</Helmet>
			<IndexDark dark>
				<header className="bg-dark">
					<Container no-vertical>
						<div className="text-white text-center grid gap-8 py-12 lg:py-24">
							<h1 className="font-valorant">updates</h1>
							<p className="text-xl">
								Classify is constantly improving with new
								features to enhance your experience.
							</p>
							<div>
								<span>
									<ButtonLink
										to="/support/contact?feature-request"
										state={{ referrer: 'Updates' }}
									>
										Request a new feature!
									</ButtonLink>
								</span>
							</div>
						</div>
					</Container>
				</header>

				<div className="grid">
					{updates.map((update, index) => (
						<article
							className="border-b border-black-alt py-24 last:border-b-0"
							key={index}
						>
							<Container no-vertical>
								<div className="grid lg:grid-cols-12">
									<div className="lg:col-span-5 2xl:col-span-6">
										<div className="mb-8">
											<h2 className="font-regular pb-4">
												Version{' '}
												{
													update.updateContent
														.versionNumber
												}{' '}
												{
													update.updateContent
														.releaseEmoji
												}
											</h2>
											<span className="text-2xl text-black-alt">
												{
													update.updateContent
														.releaseDate
												}
											</span>
										</div>
									</div>
									<div className="lg:col-span-7 2xl:col-span-6 flex flex-col">
										<div className="prose mx-auto my-0">
											<h2>{update.title}</h2>
											<div
												dangerouslySetInnerHTML={{
													__html: update.content,
												}}
											/>
											<div className="mt-12">
												<ButtonLink
													to="/#get-classify"
													anchor
												>
													Download for free!
												</ButtonLink>
											</div>
										</div>
									</div>
								</div>
							</Container>
						</article>
					))}
				</div>

				<article id="launch" className="bg-white-alt">
					<div className="py-36">
						<div className="text-center">
							<h2 className="text-black">Launch</h2>
							<span className="text-black-faded">
								1st November 2019
							</span>
							<UpdatesCarousel data={launch} />
						</div>
					</div>
				</article>
			</IndexDark>
		</>
	)
}

export const UpdatesQuery = graphql`
	query {
		allWpPost(
			filter: {
				categories: {
					nodes: { elemMatch: { slug: { eq: "updates" } } }
				}
			}
			sort: { order: DESC, fields: date }
		) {
			nodes {
				updateContent {
					releaseDate
					releaseEmoji
					versionNumber
				}
				title
				content
			}
		}
	}
`

export default updates

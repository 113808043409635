import React, { useEffect, useRef, useState } from 'react'
//import Hammer from 'hammerjs'
import '../styles/carousel.scss'

function UpdatesCarousel({ data }) {
	const [index, setIndex] = useState(1)
	const length = data.length

	const container = useRef(null)

	useEffect(() => {
		const children = container.current.children
		const current = children[index]
		const next = children[(index + 1) % length]
		const prev = children[index === 0 ? length - 1 : index - 1]
		const clearStyles = () => {
			for (let i = 0; i < children.length; i++) {
				children[i].classList.remove('current')
				children[i].classList.remove('prev')
				children[i].classList.remove('next')
			}
		}

		current.classList.add('current')
		prev.classList.add('prev')
		next.classList.add('next')

		const timeout = window.setTimeout(() => {
			clearStyles()
			setIndex((index + 1) % length)
		}, 5000)

		const increment = () => {
			window.clearTimeout(timeout)
			clearStyles()
			setIndex((index + 1) % length)
		}
		const decrement = () => {
			window.clearTimeout(timeout)
			clearStyles()
			setIndex(index === 0 ? length - 1 : index - 1)
		}

		current.onclick = null
		next.onclick = () => increment()
		prev.onclick = () => decrement()

		// let hammer;
		// if (typeof window !== 'undefined') {
		//   import('hammerjs').then(({default: Hammer}) => {
		//     hammer = new Hammer(container.current)
		//     hammer.get('swipe').set({direction: Hammer.DIRECTION_HORIZONTAL})
		//     hammer.on('swipe', e => e.direction === 2 ? increment() : decrement())
		//   })
		// }

		return () => {
			window.clearTimeout(timeout)
			//   hammer?.destroy()
		}
	})

	return (
		<div className="w-full">
			<div className="h-48 lg:h-72 my-16 w-full">
				<div
					className="relative h-full w-full overflow-x-hidden lg:overflow-x-visible"
					ref={container}
				>
					{data.map((d, i) => {
						return (
							<img
								src={d.image}
								alt={`Carousel section ${i}`}
								width="1369"
								height="1029"
								className="w-auto h-full left-0 right-0 absolute mx-auto z-0 opacity-0"
								style={{ transition: 'transform 0.5s' }}
								key={i}
							></img>
						)
					})}
				</div>
			</div>
			<div className="w-1/2 mx-auto grid gap-2 place-items-center text-center">
				<span className="text-xl text-black">{data[index].title}</span>
				<p className="text-black-alt">{data[index].body}</p>
			</div>
		</div>
	)
}

export default UpdatesCarousel
